<template>
  <div class="container mt-4">
    <div class="d-flex align-items-center mb-3" style="margin-top: 4%;">
      <b-form-datepicker v-model="startDate" class="mr-2" placeholder="Start Date"></b-form-datepicker>
      <b-form-datepicker v-model="endDate" class="mr-2" placeholder="End Date"></b-form-datepicker>
      <b-form-select v-model="filters.details" :options="detailsOptions" class="mr-2" placeholder="Name" />
      <b-form-select v-model="filters.entityType" :options="typeOptions" class="mr-2" placeholder="Type" />
      <h5 class="mr-3">({{ filteredAuditLogs.length }})</h5>
      <b-form-select v-model="filters.action" :options="actionOptions" class="mr-2" placeholder="Action" />
      <b-form-select v-model="filters.user" :options="userOptions" class="mr-2" placeholder="User" />
      <b-form-input v-model="searchQuery" class="mr-2" placeholder="Search..." />

      <b-button @click="resetFilters" variant="secondary">Reset</b-button>

    </div>
    
    <div class="table-container" style="margin-top: 6%;">
      <b-table striped hover :items="filteredAuditLogs" :fields="fields">
        <!-- Custom cell for displaying item or tour name based on conditions -->
        <template #cell(details)="data">
          <span v-if="data.item && data.item.entityType === 'service'">
            {{ determineDetailValue(data.item.oldValue?.item, data.item.newValue?.item) }}
          </span>
          <span v-else-if="data.item && data.item.entityType === 'tour'">
            {{ determineDetailValue(data.item.oldValue?.tourName, data.item.newValue?.tourName) }}
          </span>
          <span v-else>N/A</span>
        </template>
        <template #cell(id)="data">
          <span v-if="data.item && data.item.entityType === 'service'">
            {{ determineDetailValue(data.item.oldValue?.id, data.item.newValue?.id) }}
          </span>
          <span v-else-if="data.item && data.item.entityType === 'tour'">
            {{ determineDetailValue(data.item.oldValue?.id, data.item.newValue?.id) }}
          </span>
          <span v-else>N/A</span>
        </template>
        <!-- Custom cell for displaying the createdAt timestamp -->
        <template #cell(createdAt)="data">
          <span v-if="data.item && (data.item.entityType === 'service' || data.item.entityType === 'tour')">
            {{ formatDate(data.item.createdAt) }}
          </span>
          <span v-else>N/A</span>
        </template>

        <!-- Custom cell for displaying the timestamp (Updated At) -->
        <template #cell(timestamp)="data">
          <span>{{ formatDate(data.item?.timestamp) }}</span>
        </template>

        <template #cell(view)="data">
          <b-button @click="showBox(data.item)" variant="link">
            <i class="fas fa-eye"></i>
          </b-button>
        </template>
        <template #cell(restore)="data">
          <b-button @click="restoreItem(data.item)" variant="link" v-if="data.item.action === 'DELETE'">
            <i class="fas fa-undo"></i>
          </b-button>
        </template>
      </b-table>
    </div>

    <!-- Display box with old and new values -->
    <div v-if="showBoxFlag" class="box-container">
      <div class="box old-values">
        <h5>Old Values</h5>
        <pre v-for="(value, key) in boxData.oldValue" :key="key" :class="{ 'changed-red': value.changed }">
      {{ key }}: {{ value.value }}
    </pre>
      </div>
      <div class="box new-values">
        <button @click="closeBox" class="close-button">X</button>
        <h5>New Values</h5>
        <pre v-for="(value, key) in boxData.newValue" :key="key" :class="{ 'changed-green': value.changed }">
      {{ key }}: {{ value.value }}
    </pre>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    const currentDate = moment().toDate(); // Get the current date
    const startDate = moment().subtract(5, 'days').toDate(); // Get the date 5 days before

    return {
      auditLogs: [],
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'details', label: 'Name' },
        { key: 'entityType', label: 'Type' },
        { key: 'action', label: 'Action' },
        { key: 'createdAt', label: 'Created At' },
        { key: 'timestamp', label: 'Updated At' },
        { key: 'users_permissions_user', label: 'User', formatter: (value) => value ? value.data.attributes.email : 'N/A' },
        { key: 'view', label: 'View' },
        { key: 'restore', label: 'Restore' },
      ],
      showBoxFlag: false,
      boxData: {
        oldValue: {},
        newValue: {}
      },
      searchQuery: '',
      filters: {
        entityType: '',
        details: '',
        action: '',
        user: ''
      },
      startDate: startDate, 
      endDate: currentDate,
    };
  },
  async mounted() {
    await this.filterAuditLogs();
  },

  computed: {
    sortedUniqueTypes() {
      return [...new Set(this.auditLogs.map(auditLog => auditLog.entityType))].sort();
    },
    typeOptions() {
      return [{ value: '', text: 'Type' },
      ...this.sortedUniqueTypes.map(entityType => ({ value: entityType, text: entityType }))
      ];
    },
    actionOptions() {
      return [{ value: '', text: 'Action' },
      ...Array.from(new Set(this.auditLogs.map(log => log.action)))
        .map(action => ({ value: action, text: action }))
      ];
    },
    userOptions() {
      return [{ value: '', text: 'User' },
      ...Array.from(new Set(this.auditLogs.map(log => log.users_permissions_user?.data?.attributes?.email || 'N/A')))
        .map(user => ({ value: user, text: user }))
      ];
    },
    detailsOptions() {
      return [{ value: '', text: 'Name' },
      ...Array.from(new Set(this.auditLogs.map(log => {
        if (log.entityType === 'service') {
          return log.oldValue?.item || log.newValue?.item || 'N/A';
        } else if (log.entityType === 'tour') {
          return log.oldValue?.tourName || log.newValue?.tourName || 'N/A';
        }
        return 'N/A';
      }))).map(detail => ({ value: detail, text: detail }))
      ];
    },
    filteredAuditLogs() {
      return this.auditLogs.filter(log => {
        const matchesType = !this.filters.entityType || log.entityType === this.filters.entityType;
        const matchesAction = !this.filters.action || log.action === this.filters.action;
        const matchesUser = !this.filters.user || (log.users_permissions_user?.data?.attributes?.email === this.filters.user);
        const matchesDetails = !this.filters.details ||
          (log.entityType === 'service' && (log.oldValue?.item === this.filters.details || log.newValue?.item === this.filters.details)) ||
          (log.entityType === 'tour' && (log.oldValue?.tourName === this.filters.details || log.newValue?.tourName === this.filters.details));

        // Check if any field contains the search query
        const matchesSearchQuery = !this.searchQuery ||
          (log.entityType && log.entityType.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
          (log.action && log.action.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
          (log.users_permissions_user?.data?.attributes?.email && log.users_permissions_user.data.attributes.email.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
          (log.oldValue && JSON.stringify(log.oldValue).toLowerCase().includes(this.searchQuery.toLowerCase())) ||
          (log.newValue && JSON.stringify(log.newValue).toLowerCase().includes(this.searchQuery.toLowerCase()));

        return matchesType && matchesAction && matchesUser && matchesDetails && matchesSearchQuery;
      });
    }
  },
  watch: {
  startDate(newVal) {
    if (newVal && this.endDate) {
      this.filterAuditLogs();
    }
  },
  endDate(newVal) {
    if (newVal && this.startDate) {
      this.filterAuditLogs();
    }
  }
},

  methods: {
    async restoreService(oldValue) {
      const serviceData = {
        ...oldValue,
        cities: oldValue.cities || [],
      };
      // Check if the service already exists
      const existingServiceResponse = await this.$xhr.get(`/api/services?filters[id]=${oldValue.id}`);
      if (existingServiceResponse.data.data.length > 0) {
        this.$bvToast.toast('Service already exists or has been restored.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 2000,
          solid: true
        });
        return;
      }

      const response = await this.$xhr.post('/api/services', { data: serviceData });

      if (response.status === 200) {
        this.$bvToast.toast('Service restored successfully!', {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true
        });
        await this.filterAuditLogs();
      }
    },

    async restoreTour(oldValue) {
      const tourData = {
        ...oldValue,
        cities: oldValue.cities || [],
      };
      const existingTourResponse = await this.$xhr.get(`/api/tours?filters[id]=${oldValue.id}`);
      if (existingTourResponse.data.data.length > 0) {
        this.$bvToast.toast('Tour already exists or has been restored.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 2000,
          solid: true
        });
        return;
      }

      const response = await this.$xhr.post('/api/tours', { data: tourData });

      if (response.status === 200) {
        this.$bvToast.toast('Tour restored successfully!', {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true
        });
        await this.filterAuditLogs();
      }
    }
    ,
    handleRestore(entityType, oldValue) {
      if (entityType === 'service') {
        this.restoreService(oldValue);
      } else if (entityType === 'tour') {
        this.restoreTour(oldValue);
      }
    },
    restoreItem(item) {
      const { entityType, oldValue } = item;
      this.handleRestore(entityType, oldValue);
    },
    async filterAuditLogs() {
      if (!this.startDate || !this.endDate) return;
      this.loading = true; 
      const formattedStartDate = moment(this.startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(this.endDate).format('YYYY-MM-DD');
      const response = await this.$xhr.get(`/api/audit-logs?populate=*&filters[date][$gte]=${formattedStartDate}&filters[date][$lte]=${formattedEndDate}`);

      this.auditLogs = response.data.data.map(log => ({
        ...log.attributes,
        oldValue: log.attributes.oldValue || '{}',
        newValue: log.attributes.newValue || '{}'
      })).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    },

    handleDateChange(newDate) {
      this.inputDate = newDate;
      this.filterAuditLogs();

    },
    determineDetailValue(oldValue, newValue) {
      if (!oldValue || oldValue === 'N/A') {
        return newValue || 'N/A';
      }
      if (!newValue || newValue === 'N/A') {
        return oldValue;
      }
      return newValue;
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    showBox(item) {
      const oldValue = item.oldValue || {};
      const newValue = item.newValue || {};
      const differences = this.getDifferences(oldValue, newValue);

      this.boxData.oldValue = differences.oldValue;
      this.boxData.newValue = differences.newValue;
      this.showBoxFlag = true;
    },
    getDifferences(oldValue, newValue) {
      const oldDiff = {};
      const newDiff = {};

      if (typeof oldValue !== 'object' || oldValue === null) oldValue = {};
      if (typeof newValue !== 'object' || newValue === null) newValue = {};

      Object.keys({ ...oldValue, ...newValue }).forEach(key => {
        const oldVal = oldValue[key];
        const newVal = newValue[key];

        if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
          oldDiff[key] = { value: oldVal, changed: true };
          newDiff[key] = { value: newVal, changed: true };
        } else {
          oldDiff[key] = { value: oldVal, changed: false };
          newDiff[key] = { value: newVal, changed: false };
        }
      });

      return { oldValue: oldDiff, newValue: newDiff };
    },
    closeBox() {
      this.showBoxFlag = false;
      this.boxData.oldValue = {};
      this.boxData.newValue = {};
    },
    resetFilters() {
      this.filters = {
        entityType: '',
        details: '',
        action: '',
        user: ''
      };
      this.startDate = null;
      this.endDate = null;
      this.filterAuditLogs();
    }
  }
};
</script>
<style scoped>
.container {
  max-width: 100%;
}

.table-container {
  max-height: 900px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.b-table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
}

.box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 1200px;
  height: 1000px;
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.box {
  width: 48%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  font-size: 14px;
  height: 100%;
  overflow-y: auto;
}

.old-values {
  background-color: #f8f9fa;
}

.new-values {
  background-color: #e9ecef;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

.changed-red {
  color: red;
  font-weight: bold;
}

.changed-green {
  color: green;
  font-weight: bold;
}

.d-flex {
  display: flex;
  position: fixed;
}

.align-items-center {
  align-items: center;
}

.mb-3 {
  margin-bottom: 1rem;
  margin-top: 2%;
  margin-left: 1.3%;
}

.mr-3 {
  max-width: 20rem;
}

.mr-2 {
  max-width: 15rem;
}

.mr-4 {
  margin-right: 1rem;
  max-width: 25rem;
  margin-inline: 2rem;
}
</style>
