<template>
  <div class="container mt-4">
    <div class="row mb-3 align-items-center">
      <div class="col-md-6 text-center">
        <h2>Cities</h2>
        <input type="text" v-model="searchTerm" class="form-control" placeholder="Search for a city" />
      </div>
      <div class="col-md-6 text-end">
        <button class="btn btn-primary btn-sm" @click="toggleForm">
          {{ showForm ? 'Close Form' : 'Add City' }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="city-container">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="city in filteredCities" :key="city.id">
                <td>{{ city.id }}</td>
                <td>{{ city.attributes.city }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-md-6" v-if="showForm">
        <div class="card p-3">
          <h2>Add City</h2>
          <form @submit.prevent="handleSubmit">
            <div class="mb-3">
              <label for="city" class="form-label">City:</label>
              <input type="text" v-model="form.city" class="form-control" required />
            </div>
            <button type="submit" class="btn btn-primary">Add</button>
            <button type="button" class="btn btn-secondary" @click="resetForm">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cities: [],
      showForm: false,
      form: { city: '' },
      searchTerm: '',
    };
  },
  created() {
    this.fetchCities();
  },
  computed: {
    filteredCities() {
      return this.cities.filter(city =>
        city.attributes.city.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  },
  methods: {
    async fetchCities() {
      const { data } = await this.$xhr.get("/api/cities?populate=*");
      this.cities = data.data;
    },
    async handleSubmit() {
      const cityExists = this.cities.some(
        city => city.attributes.city.toLowerCase() === this.form.city.toLowerCase()
      );

      if (cityExists) {
        this.$bvToast.toast('City already exists.', {
          title: 'Duplicate',
          variant: 'warning',
          solid: true,
          autoHideDelay: 5000
        });
      } else {
        const formData = { city: this.form.city };
        await this.$xhr.post("/api/cities", { data: formData });
        this.fetchCities();
        this.resetForm();

        this.$bvToast.toast('City added successfully!', {
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000
        });
      }
    },
    toggleForm() {
      this.showForm = !this.showForm;
      this.resetForm();
    },
    resetForm() {
      this.form.city = '';
    },
  },
};
</script>

<style scoped>
.city-container {
  max-height: 75%;
  overflow-y: auto;
}
.table {
  width: 100%; 
}
</style>
