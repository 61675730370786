<template>
  <div class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ isEditing ? 'Edit Service' : 'Add New Service' }}</h5>
          <button type="button" class="close" aria-label="Close" @click="handleClose">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="item">Item <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="item" v-model="form.item" required>
              </div>
              <div class="form-group col-md-6">
                <label for="type">Type <span class="text-danger">*</span></label>
                <select class="form-control" id="type" v-model="form.type" @change="handleTypeChange" required>
                  <option v-for="option in typeOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </div>
            </div>

            <!-- Conditionally Render Fields Based on Type -->
            <!-- Show Price for 'bulk' or 'hourly' and hide Adult/Child Price fields -->
            <div v-if="form.type === 'bulk' || form.type === 'hourly'" class="form-row">
              <div class="form-group col-md-12">
                <label for="price">Price (Gross) <span class="text-danger">*</span></label>
                <input type="decimal" step="1" class="form-control" id="price" v-model="form.price" required>
              </div>

            </div>

            <!-- Show Adult/Child Price fields for 'per person' and hide Price field -->
            <div v-if="form.type === 'per person'" class="form-row">
              <div class="form-group col-md-6">
                <label for="adultPriceGross">Adult Price (Gross)<span class="text-danger">*</span></label>
                <input type="decimal" step="1" class="form-control" id="adultPriceGross" v-model="form.adultPrice_gross"
                  required>
              </div>
              <div class="form-group col-md-6">
                <label for="childPriceGross">Child Price (Gross)<span class="text-danger">*</span></label>
                <input type="decimal" step="1" class="form-control" id="childPriceGross" v-model="form.childPrice_gross"
                  required>
              </div>
              <!-- <div class="form-group col-md-6">
                <label for="adultPriceNet">Adult Price (Net)</label>
                <input type="decimal" step="1" class="form-control" id="adultPriceNet" v-model="form.adultPrice_net"
                  required>
              </div>
              <div class="form-group col-md-6">
                <label for="childPriceNet">Child Price (Net)</label>
                <input type="decimal" step="1" class="form-control" id="childPriceNet" v-model="form.childPrice_net"
                  required>
              </div> -->

            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="tax">Tax <span class="text-danger">*</span></label>
                <select class="form-control" id="tax" v-model="form.tax" required>
                  <option v-for="option in taxOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </div>

              <div class="form-group col-md-4">
                <label for="rate">Rate <span class="text-danger">*</span></label>
                <input type="decimal" step="1" class="form-control" id="rate" v-model="form.rate" required>
              </div>
              <div class="form-group col-md-4">
                <label for="category">Category <span class="text-danger">*</span></label>
                <select class="form-control" id="category" v-model="form.catagory" required>
                  <option v-for="option in categoryOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </div>
            </div>

            <div class="form-row">

              <div class="form-group col-md-6">
                <label for="remarks">Remarks</label>
                <textarea class="form-control" id="remarks" v-model="form.remarks"></textarea>
              </div>

              <div class="form-group col-md-6">
                <label for="cancellation">Cancellation</label>
                <input type="text" class="form-control" id="cancellation" v-model="form.cancellation">
              </div>
            </div>
            <div class="form-row">

              <div class="form-group col-md-6">
                <label for="year">Year <span class="text-danger">*</span></label>
                <select class="form-control" id="year" v-model="form.year" required>
                  <option v-for="year in yearOptions" :key="year" :value="year">{{ year }}</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="supplier">Supplier <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="supplier" v-model="form.supplier" required>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="cities">Select City</label>
                <select class="form-control" id="cities" v-model="selectedCity">
                  <option v-for="city in cityOptions" :key="city.value" :value="city.value">
                    {{ city.text }}
                  </option>
                </select>
                <button type="button" class="btn btn-secondary mt-2" @click="addCity">+</button>
                <div class="form-group mt-2">
                  <label>Selected Cities</label>
                  <div class="selected-cities">
                    <div class="city-item" v-for="(city, index) in form.cities" :key="index">
                      {{ getCityName(city.id) }}
                      <b-button size="sm" class="remove-btn" @click="removeCity(index)">
                        <i class="fas fa-trash"></i>
                      </b-button>
                    </div>
                  </div>
                  <div v-if="cityError" class="text-danger">Please select at least one city.</div>
                </div>
              </div>


            </div>
            <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Submit' }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    service: Object,
    cities: {
      type: Array,
    }
  },
  data() {
    const startYear = 2024;
    return {
      form: {
        item: '',
        adultPrice_net: null,
        childPrice_net: null,
        price: '',
        type: '',
        tax: '',
        rate: null,
        adultPrice_gross: null,
        childPrice_gross: null,
        catagory: '',
        remarks: '',
        cancellation: '',
        year: null,
        cities: [],
        supplier: ''
      },
      yearOptions: Array.from({ length: 5 }, (v, i) => startYear + i), // Starting from 2024, going forward 20 years

      selectedCity: null,
      typeOptions: ['per person', 'bulk', 'hourly'],
      taxOptions: ['Toms', 'VAT', 'Rcv'],
      categoryOptions: ['Accommodation', 'F&B', 'Staff', 'Transportation', 'Insides', 'Additional Services'],
      cityOptions: [],
      cityError: false
    };
  },
  computed: {
    isEditing() {
      return this.service !== null;
    }
  },
  mounted() {
    this.cityOptions = Array.isArray(this.cities) && this.cities.length > 0
      ? [{ value: null, text: 'Select a city' },
      ...this.cities
        .sort((a, b) => a.attributes.city.localeCompare(b.attributes.city))
        .map(city => ({ value: city.id, text: city.attributes.city }))]
      : [{ value: null, text: 'No cities available' }];

    if (this.isEditing && this.service) {
      const citiesData = this.service.attributes.cities?.data;
      this.form = {
        ...this.service.attributes,
        cities: Array.isArray(citiesData) ? citiesData.map(city => ({ id: city.id })) : [],
      };
    }
  },

  methods: {

    handleTypeChange() {
      if (this.form.type === 'bulk' || this.form.type === 'hourly') {
        this.form.adultPrice_net = null;
        this.form.childPrice_net = null;
        this.form.adultPrice_gross = null;
        this.form.childPrice_gross = null;
      } else if (this.form.type === 'per person') {
        this.form.price = null;
      }
    },
    handleClose() {
      this.$emit('close');
    },
    addCity() {
      if (this.selectedCity) {
        this.form.cities.push({ id: this.selectedCity });
        this.selectedCity = null;  // Reset the dropdown
        this.cityError = false;  // Reset error state
      } else {
        this.cityError = true;
      }
    },
    removeCity(index) {
      this.form.cities.splice(index, 1);
    },
    getCityName(cityId) {
      const city = this.cities.find(city => city.id === cityId);
      return city ? city.attributes.city : 'Unknown';
    },
    normalizePrice(value) {
      if (typeof value === 'string') {
        return value.replace(',', '.');
      }
      return value;
    },
    async submitForm() {
      this.cityError = false;

      if (this.form.cities.length === 0 || this.form.cities.some(city => !city.id)) {
        this.cityError = true;
        this.$bvToast.toast('Please select at least one city.', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });
        return;
      }

      if (!this.isEditing) {
        const itemExists = await this.checkItemExists(this.form.item);
        if (itemExists) {
          this.$bvToast.toast('An item with the same name already exists.', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
          return;
        }
      }

      if (this.form.type === 'per person') {
        this.form.adultPrice_gross = this.normalizePrice(this.form.adultPrice_gross);
        this.form.childPrice_gross = this.normalizePrice(this.form.childPrice_gross);
        if (this.form.tax === 'Toms') {
          this.form.adultPrice_net = Number(this.form.adultPrice_gross).toFixed(2);
          this.form.childPrice_net = Number(this.form.childPrice_gross).toFixed(2);
        } else {
          const rateMultiplier = 1 + (Number(this.form.rate) / 100);
          this.form.adultPrice_net = (Number(this.form.adultPrice_gross) / rateMultiplier).toFixed(2);
          this.form.childPrice_net = (Number(this.form.childPrice_gross) / rateMultiplier).toFixed(2);
        }
      } else {
        this.form.price = this.normalizePrice(this.form.price);
      }

      const formData = {
        ...this.form,
        cities: this.form.cities.map(city => city.id)
      };

      if (this.isEditing) {
        await this.$xhr.put(`/api/services/${this.service.id}`, { data: formData });
      } else {
        await this.$xhr.post('/api/services', { data: formData });
      }

      this.$emit('service-added');
      this.handleClose();
    },

    async checkItemExists(item) {
      const response = await this.$xhr.get(`/api/services?filters[item][$eq]=${item}`);
      return response.data.data.length > 0;
    }
  }
};
</script>



<style scoped>
.modal-content {
  margin: 10px auto;
}

.modal-dialog-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 50%;
}

.modal-body {
  max-height: 90vh;
  overflow-y: auto;
}

.remove-btn {
  background-color: red;
  color: white;
  margin-left: 1rem;
}

.selected-cities {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.city-item {
  width: calc(20% - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
